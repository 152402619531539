import React from "react"
import { StyledBurger } from "./Burger.styled"

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
}

const Burger: React.FC<Props> = ({ open, setOpen }) => {
  return (
    <div className="block sm:hidden">
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
    </div>
  )
}

export default Burger
