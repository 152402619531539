import React from "react"
import { StyledMenu } from "./Menu.styled"
import Social from "../social/Social"

interface Props {
  open: boolean
}

const Menu: React.FC<Props> = ({ open }) => {
  return (
    <StyledMenu open={open}>
      <a href="/">
        <span role="img" aria-label="HOME">
          &#x1f481;&#x1f3fb;&#x200d;&#x2642;&#xfe0f;
        </span>
        HOME
      </a>
      <a href="/blog" className="flex items-center">
        <span role="img" className="mr-4" aria-label="BLOG">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="35px"
            width="35px"
            viewBox="0 0 511.995 511.995"
          >
            <g fill="#7383bf">
              <path d="M503.47 8.528S322.307 93.153 86.975 364.684l60.339 60.339C418.845 189.7 503.47 8.528 503.47 8.528zM111.116 437.089l-36.207-36.198 18.099-30.174 48.273 48.273z" />
            </g>
            <path
              d="M111.116 437.089L93.008 418.99l-18.099-18.099c-10.01 10.01-26.197 10.01-36.207 0L8.537 503.462l102.579-30.174c-10.01-10.001-10.01-26.189 0-36.199z"
              fill="#ebba16"
            />
            <g fill="#556080">
              <path d="M121.032 326.327c-11.196 12.365-22.519 25.054-34.048 38.357l60.339 60.339c13.303-11.529 25.993-22.852 38.357-34.048l-64.648-64.648zM324.347 253.153c16.299-17.903 31.317-35.089 45.073-51.465l-59.102-59.102c-16.375 13.764-33.57 28.774-51.465 45.073l65.494 65.494z" />
            </g>
            <g fill="#4d007e">
              <path d="M147.314 433.557a8.493 8.493 0 01-6.033-2.5l-60.339-60.339a8.537 8.537 0 01-.418-11.622C202.602 218.236 309.891 128.44 378.406 77.914 452.936 22.95 497.975 1.676 499.852.797a8.549 8.549 0 019.643 1.698 8.536 8.536 0 011.698 9.643c-.879 1.877-22.153 46.916-77.124 121.446-50.526 68.514-140.322 175.804-281.182 297.882a8.426 8.426 0 01-5.573 2.091zM98.64 364.275l49.075 49.084C352.968 234.252 449.685 86.233 482.376 29.631c-56.61 32.683-204.63 129.4-383.736 334.644z" />
              <path d="M111.116 445.623a8.529 8.529 0 01-6.042-2.5l-36.207-36.207a8.52 8.52 0 01-1.28-10.428l18.108-30.165a8.54 8.54 0 016.272-4.079 8.585 8.585 0 017.074 2.441l48.273 48.273a8.544 8.544 0 012.441 7.074 8.503 8.503 0 01-4.079 6.272l-30.174 18.099a8.52 8.52 0 01-4.386 1.22zm-25.464-46.055l26.786 26.778 15.087-9.054-32.819-32.811-9.054 15.087z" />
              <path d="M8.537 511.995a8.53 8.53 0 01-8.183-10.939l30.165-102.579a8.558 8.558 0 016.076-5.862 8.519 8.519 0 018.141 2.236c6.46 6.46 17.681 6.46 24.141 0 3.2-3.2 8.866-3.2 12.066 0l36.207 36.207a8.523 8.523 0 010 12.066c-6.647 6.656-6.656 17.485 0 24.141a8.53 8.53 0 01-3.626 14.217L10.943 511.654a8.638 8.638 0 01-2.406.341zm35.114-97.69l-22.519 76.561 76.578-22.528c-4.087-9.822-3.388-21.188 2.091-30.49l-25.651-25.65c-9.046 5.367-20.83 6.152-30.499 2.107z" />
              <path d="M8.537 511.995a8.509 8.509 0 01-6.033-2.5 8.523 8.523 0 010-12.066l42.24-42.24a8.523 8.523 0 0112.066 0 8.523 8.523 0 010 12.066l-42.24 42.24a8.477 8.477 0 01-6.033 2.5zM147.314 433.557a8.493 8.493 0 01-6.033-2.5l-60.339-60.339a8.537 8.537 0 01-.418-11.622c11.571-13.355 22.938-26.086 34.176-38.494a8.51 8.51 0 016.118-2.799c2.176-.119 4.591.845 6.238 2.492l64.649 64.649a8.503 8.503 0 012.492 6.246 8.493 8.493 0 01-2.799 6.11c-12.416 11.238-25.148 22.605-38.502 34.176a8.507 8.507 0 01-5.582 2.081zM98.64 364.275l49.075 49.084a2296.41 2296.41 0 0025.574-22.707l-51.951-51.942a2390.781 2390.781 0 00-22.698 25.565zM324.347 261.678a8.513 8.513 0 01-6.033-2.5l-65.493-65.493a8.51 8.51 0 01-2.492-6.229 8.52 8.52 0 012.782-6.11c17.348-15.795 34.748-31.036 51.729-45.303a8.517 8.517 0 0111.52.503l59.102 59.102a8.533 8.533 0 01.503 11.52c-14.268 16.981-29.508 34.381-45.303 51.729a8.52 8.52 0 01-6.11 2.782l-.205-.001zm-53.12-73.719l52.821 52.821a1749.712 1749.712 0 0033.801-38.605l-48.009-48.009a1688.73 1688.73 0 00-38.613 33.793z" />
            </g>
          </svg>
        </span>
        BLOG
      </a>
      <a href="/project" className="flex items-center">
        <span role="img" className="mr-4" aria-label="PROJECTS">
          <svg
            viewBox="0 -1 466.104 466"
            height="35px"
            width="35px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M194.105 440.313c0 13.253 10.743 24 24 24h32c13.254 0 24-10.747 24-24v-48h-80zm0 0"
              fill="#ebebeb"
            />
            <path
              d="M156.406 305.566a144.435 144.435 0 01-19.71-15.261c-5.223 20.082-24.962 32.816-45.41 29.3-20.454-3.515-34.806-22.105-33.028-42.78 1.777-20.677 19.094-36.544 39.847-36.513 1.223 0 2.399.254 3.567.36a143.907 143.907 0 01-11.488-56.36H80l-5.527 26.293a8 8 0 01-12.368 4.946L40 200.312l-24 24 15.2 22.102a7.991 7.991 0 01.84 7.508 7.984 7.984 0 01-5.782 4.86L0 264.311v32l26.29 5.528a8.014 8.014 0 015.784 4.86 8.002 8.002 0 01-.844 7.507L16 336.313l24 24 22.105-15.204a8.002 8.002 0 0112.375 4.945L80 376.313h32l5.52-26.296a7.995 7.995 0 014.863-5.79 7.987 7.987 0 017.512.844L152 360.313l25.113-25.114a46.59 46.59 0 00-20.707-29.633zm0 0"
              fill="#c9dfff"
            />
            <path
              d="M378.105 184.313c-.007-69.043-49.02-128.368-116.82-141.399-67.8-13.031-135.312 23.89-160.914 88.012-25.601 64.12-2.094 137.39 56.035 174.64a45.875 45.875 0 0121.7 38.418v32.329c0 13.253 10.742 24 24 24h64c13.253 0 24-10.747 24-24v-31.665a47.128 47.128 0 0122.16-39.378 143.879 143.879 0 0065.84-120.957zm0 0"
              fill="#ffb41f"
            />
            <path
              d="M427.648 99.68l25.153-18.864L424 42.414l-25.152 18.863a15.998 15.998 0 01-17.746.961 16.005 16.005 0 01-7.688-16.023l4.441-31.113-47.52-6.79-4.44 31.11a15.988 15.988 0 01-11.864 13.242 15.993 15.993 0 01-16.773-5.898l-18.86-25.153L240 50.414l18.855 25.152c3.88 5.18 4.258 12.18.961 17.746s-9.62 8.598-16.023 7.688l-31.113-4.441-6.793 47.52 31.12 4.44a15.998 15.998 0 017.337 28.641l-25.145 18.863L248 234.422l25.145-18.856a16.004 16.004 0 0117.753-.968 16.007 16.007 0 017.688 16.03l-4.48 31.106 47.519 6.793 4.48-31.12a15.993 15.993 0 0111.868-13.235c6.27-1.61 12.89.723 16.77 5.898l18.866 25.145 38.399-28.8-18.871-25.145a15.997 15.997 0 0115.063-25.438l31.113 4.445 6.792-47.52-31.113-4.444a16 16 0 01-7.344-28.633zM336 194.414c-30.93 0-56-25.07-56-56 0-30.926 25.07-56 56-56s56 25.074 56 56c0 30.93-25.07 56-56 56zm0 0"
              fill="#1cc8eb"
            />
            <path d="M194.105 416.313h80v16h-80zm0 0" fill="#9e9e9e" />
            <g fill="#fa0">
              <path d="M226.105 352.313h16v24h-16zm0 0M242.105 336.313h-16c-.007-21.153-13.05-40.114-32.8-47.684-39.371-15.395-66.723-51.645-70.715-93.73-3.992-42.086 16.05-82.833 51.824-105.356l8.488 13.535c-30.672 19.3-47.863 54.23-44.441 90.313 3.418 36.078 26.863 67.156 60.617 80.351 25.93 9.918 43.047 34.809 43.027 62.57zm0 0M394.105 296.313h40a8 8 0 010 16h-40a8 8 0 010-16zm0 0M378.105 344.316a7.998 7.998 0 0111.266-1.027l35.711 29.754a7.998 7.998 0 011.027 11.266c-2.832 3.394-7.875 3.855-11.27 1.027l-35.71-29.754a7.998 7.998 0 01-1.024-11.266zm0 0M346.105 360.313c4.418 0 8 3.578 8 8v40a8 8 0 01-16 0v-40c0-4.422 3.583-8 8-8zm0 0M7.93 134.602l48.476-12.11a7.997 7.997 0 019.7 5.82 7.995 7.995 0 01-5.825 9.696l-48.476 12.11a7.997 7.997 0 01-9.7-5.821 7.995 7.995 0 015.825-9.695zm0 0M18.102 48.316c2.566-3.593 7.562-4.421 11.156-1.855l42.976 30.7a7.996 7.996 0 11-9.293 13.01L19.965 59.474a7.996 7.996 0 01-1.863-11.157zm0 0M89.75.305a7.998 7.998 0 019.73 5.77L111.88 54.57a8 8 0 11-15.5 3.965L83.98 10.04A8.002 8.002 0 0189.75.305zm0 0" />
            </g>
          </svg>
        </span>
        PROJECTS
      </a>
      <Social />
    </StyledMenu>
  )
}
export default Menu
