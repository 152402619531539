import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import bg from "../images/bg.jpg"

import Nav from "./nav"
import "./layout.css"
import Burger from "./Burger/Burger"
import Menu from "./Menu/Menu"

interface Props {
  readonly title?: string
  readonly children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //         social {
  //           twitter
  //           linkedin
  //           medium
  //           github
  //         }
  //       }
  //     }
  //   }
  // `)

  const mainStyle = {
    backgroundImage: `radial-gradient(circle, rgba(63,36,233,0.85) 0%, rgba(112,0,255,0.9) 100%), url(${bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    transform: "translate3d(0,0,-500px)",
    position: "fixed",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 0,
  }

  const [open, setOpen] = useState(false)

  return (
    <main className="h-full sm:h-screen">
      <div style={mainStyle}></div>
      <Nav />
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} />
      <section
        className="py-12 px-8 text-white w-full absolute  top-0
     h-full  sm:pl-10 sm:pr-16 sm:left-25 sm:max-w-full  lg:max-w-2xl"
      >
        {children}
      </section>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
