import React from "react"
import { Link } from "gatsby"
import Social from "./social/Social"

const Nav: React.FC = () => {
  return (
    <nav className="hidden sm:flex fixed left-0 top-0 w-25 h-screen text-2xl py-16 pl-8 text-white max-w-xs flex flex-col justify-between item-center font-monda">
      <ul className="m-0 p-0 list-none ">
        <li className="mb-4 transition duration-500 ease-in-out hover:bg-black">
          <Link to="/">/HOME</Link>
        </li>
        <li className="mb-4 transition duration-500 ease-in-out hover:bg-black">
          <Link to="/blog">/BLOG</Link>
        </li>
        <li className="mb-4 transition duration-500 ease-in-out hover:bg-black">
          <Link to="/project">/PROJECTS</Link>
        </li>
      </ul>
      <Social />
      <div>
        <p className="text-sm font-mont my-1">
          Built with &#10084;&#65039; and &#9749;
        </p>
        <p className="text-sm font-mont ">Developed from Gatsby and Strapi</p>
      </div>
    </nav>
  )
}

export default Nav
