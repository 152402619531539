import React from "react"

const openNewTab = (url: string) => {
  window.open(url, "_blank")
}

interface props {
  readonly url: string
  readonly children: React.ReactNode
}

const SocialElement: React.FC<props> = ({ url, children }) => {
  return (
    <div
      style={styles}
      className="mr-4 w-fit p-2 my-2 flex items-center justify-center hover:animate-pulse"
    >
      <button onClick={() => openNewTab(url)}>{children}</button>
    </div>
  )
}

export default SocialElement

const styles = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  backgroundColor: "#9EFF00",
}
